.singleRowLogoMarquee {
  height: 212px;
}

.singleRowLogoMarquee.hasLink {
  cursor: pointer;
}

.wrapper {
  margin-right: var(--layout-gutter-s);
  margin-left: var(--layout-gutter-s);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.marquee {
  position: relative;
  flex: 1 1 auto;
}

.marquee::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100%;
  background: linear-gradient(to right, var(--base-color-white), transparent);
  z-index: 1;
}

.marquee::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100%;
  background: linear-gradient(to right, transparent, var(--base-color-white));
  z-index: 1;
}

.action {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 32px;

  & a {
    color: var(--color-text-default) !important;
  }
}

.card {
  width: 168px;
  height: 120px;
  border-radius: 6px;
  border: 1px solid var(--brand-color-grey-2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--space-s);
}

.cardTitle {
  font-family: var(--font-family-headline);
  font-size: var(--text-body-m);
  color: var(--color-text-default);
}

.cardBottom {
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.arrowIcon {
  margin-right: 0;
  margin-left: auto;
  color: var(--color-text-default);
  font-size: 1em;
}
