.root {
  background: var(--brand-color-red-7);

  .description {
    opacity: 0;
    transform: translateX(140px);
    transition: transform 0.7s ease-in-out, opacity 0.7s ease-in-out;
  }

  .ui {
    transform: translateX(150px);
    transition: transform 0.7s ease-in-out;
  }
}

.root[data-inview='true'] {
  .description {
    opacity: 1;
    transform: translateX(0);
  }
  .ui {
    transform: translateX(0);
  }
}
