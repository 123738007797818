.root {
  background: radial-gradient(
      88.29% 100% at 11.71% 100%,
      rgba(144, 183, 246, 0.61) 0%,
      rgba(144, 183, 246, 0) 100%
    ),
    var(--brand-color-blue-2, #dfebfe);
}

.container {
  transition: transform 0.1s linear;
}
