.root {
  background: radial-gradient(
      162.37% 78.4% at 100% -30.43%,
      rgba(175, 143, 255, 0.6) 0%,
      rgba(175, 143, 255, 0) 100%
    ),
    var(--brand-color-purple-2);

  .container {
    transform: translateY(143px);
    transition: transform 0.7s ease-in-out;
  }
}

.root[data-inview='true'] {
  .container {
    transform: translateY(0);
  }
}
