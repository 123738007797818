.root {
  background: radial-gradient(
      201.95% 97.51% at 105.59% 0%,
      rgba(87, 199, 164, 0.67) 0%,
      rgba(87, 199, 164, 0) 100%
    ),
    var(--brand-color-green-2);
}

.circle,
.path {
  will-change: transform;
}
