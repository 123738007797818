.root {
  background: radial-gradient(
      98.91% 100% at 100% 0%,
      rgba(235, 80, 112, 0.4) 0%,
      rgba(235, 80, 112, 0) 100%
    ),
    #ffe3e8;
}

.bar,
.list {
  transition: transform 0.1s linear;
}
