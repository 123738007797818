.root {
  background: radial-gradient(
      157.78% 100% at 50% 100%,
      #90bbff 0%,
      rgba(165, 199, 254, 0) 100%
    ),
    var(--brand-color-blue-2);

  .one {
    transform: translateY(-30px);
    transition: transform 0.7s ease-in-out;
  }

  .two {
    transform: translateY(-80px);
    transition: transform 0.7s ease-in-out;
  }
}

.root[data-inview='true'] {
  .one {
    transform: translateY(0);
  }

  .two {
    transform: translateY(0);
  }
}
